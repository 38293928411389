import { render, staticRenderFns } from "./PaymentGold.vue?vue&type=template&id=af509fac&scoped=true&"
import script from "./PaymentGold.vue?vue&type=script&lang=js&"
export * from "./PaymentGold.vue?vue&type=script&lang=js&"
import style0 from "./PaymentGold.vue?vue&type=style&index=0&id=af509fac&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af509fac",
  null
  
)

export default component.exports
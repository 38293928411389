<template>
  <div class="form">

    <form class="form__wrap" @submit.prevent="submit">
        <div class="form__content">
            <h1 class="form__title">Форма оплаты</h1>
            <label for="amount" class="form__label">Количество аккаунтов</label>
            <input id="amount" v-model="count" type="number" class="form__input"
            placeholder="Например, 5">
            <span class="form__error-msg">Заполните поле, чтобы продолжить</span>

            <label for="price" class="form__label">Стоимость участия в рублях</label>
            <input id="price" v-model="amount" type="number" class="form__input">
            <span class="form__error-msg">Заполните поле, чтобы продолжить</span>

            <label for="email" class="form__label">Ваш e-mail (пришлем подтверждение)</label>
            <input id="email" v-model="email" type="email" required class="form__input">
            <span class="form__error-msg">Заполните поле, чтобы продолжить</span>

            <label for="textarea" class="form__label">
              Список аккаунтов (в столбик через @, без нумерации)
              </label>
            <textarea id="textarea" v-model="accounts" required class="form__field"
             placeholder="Например, @alexandramitroshina"></textarea>
            <span class="form__error-msg">Заполните поле, чтобы продолжить</span>

            <span class="form__total">Сумма к оплате: {{total}} Р </span>

            <button type="submit" class="form__btn form__btn--margin">Оплатить</button>

             <div class="form__checkbox">
                <div class="form__checkbox-wrap">
                    <input class="form_input"  required id="checkbox" type="checkbox">
                    <label for="checkbox" class="form__checkbox-label">
                        Я согласен с <a  href="/offer.html" class="form__link">Офертой</a>
                        <span> и </span>
                        <a href="/privacy.html" class="form__link">Политикой конфиденциальности</a>
                    </label>
                </div>
                <span class="form__error-msg">Выберите чекбокс, чтобы продолжить</span>
            </div>
        </div>
    </form>
</div>
</template>

<script>
import { CreateGiveGold, GetCoeff } from '../api';

export default {
  name: 'home',
  data: () => ({
    count: 1,
    amount: 1000,
    email: '',
    accounts: '',
    total: 0,
    coeff: 0,
  }),
  watch: {
    accounts() {
      this.recalc();
    },
    count() {
      this.recalc();
    },
    amount() {
      this.recalc();
    },
  },

  methods: {
    recalc() {
      const coeff = this.amount <= 9499 ? 0.5 : 0.4;
      const val = this.count * this.amount * coeff;
      this.total = val.toFixed(2);
    },
    async submit() {
      const response = await CreateGiveGold({
        count: this.count,
        amount: this.amount,
        email: this.email,
        accounts: this.accounts,
      });
      if (response.url) {
        window.location = response.url;
      }
    },
  },
  async mounted() {
    const response = await GetCoeff();
    this.coeff = response.value;
    this.recalc();
  },
};
</script>
<style scoped>
 .form_input{
   margin-right: 0.6em;
 }
 textarea {
  font-size: 16px;
}
</style>
